import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import ApiData from '../components/apiData'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../components/style.css'
import Layout from '../components/layout'
const IndexPage = () => (
  <Layout>
    <Helmet>
    <meta http-equiv="refresh" content="30" />
    </Helmet>
    <Container fluid className="scoreBoard">
      <ApiData />
    </Container>
  </Layout>
)

export default IndexPage
