import React from 'react'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'

const categories = [ 'Battery', 'Angular Velocity', 'Downlink Data', 'Wheel Speed']

export default class TeamList extends React.Component {
  state = {
    teams: []
  }

  componentDidMount() {
    axios.get(`https://finals.2022.hackasat.com/stoplight.json`).then(res => {
      const teams = res.data.stoplights
      const masked = false
      const timeStamp = res.data.generated_at
      this.setState({ teams, masked, timeStamp })
    })
  }

  render() {
    function score(e) {
      return e.was_available ? <span className="healthy">Healthy</span> : <span className="degraded">Degraded</span>;
    }

    return (
      <>
        <h1>Hack-A-Sat 3 Satellite Status Dashboard</h1>
        <div>
          <Row className="phaseIcons">
            <Col xs={6} md={3}></Col>
            {categories.map(cat => (
              <Col key={cat}>
                {cat}
              </Col>
            ))}
          </Row>
          {Object.values(this.state.teams).map((team, idx) => (
              <Row key={team[0].team_id}>
                <Col xs={6} md={3} className="team-names">
                  <span>{team[0].team_name}</span>
                  <div className={`team${team[0].team_id} team-circle`} />
                </Col>
                {team.map((point, idx) => (
                  <Col
                    key={idx}
                  >
                    {score(point)}
                  </Col>
                ))}
                {categories.slice(team.length).map(cat => (
                  <Col
                    key={idx}
                  >
                    <span className="offline">Offline</span>
                  </Col>
                ))}
              </Row>
            )
          )}
        </div>
        <div className="dateTimeWrapper">
          <p>UPDATED</p>
          <div className="dateTime">{this.state.timeStamp}</div>
        </div>
      </>
    )
  }
}
